import {
  FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  SUMMARIZE_MESSAGE_SUCCESS,
  SUMMARIZE_MESSAGE_FAILED,
  ADD_PHONE_NUMBER,
} from "./Types";
import axios from "./Axios";
import { returnErrors } from "./Errors";

export const FetchAllWhatAppMessagesAction = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/messaging/all-chat-sessions/`);

    dispatch({
      type: FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));

    dispatch({
      type: FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
    });
    throw err;
  }
};

export const FetchIndividualWhatsAppMessagesAction =
  (number) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/messaging/chat-session/${number}/`);

      dispatch({
        type: FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
      });
      throw err;
    }
  };

export const SendMessageAction = (to, message) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/messaging/send-message/`, {
      to,
      message,
    });

    dispatch({
      type: SEND_MESSAGE_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: SEND_MESSAGE_FAILED,
    });
    throw err;
  }
};

export const resetHasRespondedToWhatsAppMessage = () => (dispatch) => {
  dispatch({
    type: RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  });
};

export const SummarizeMessage =
  (phone_number, start_date, end_date) => async (dispatch) => {
    try {
      // Dispatch loading state
      const res = await axios.post(`/api/messaging/get-and-summarize-chat/`, {
        phone_number,
        start_date,
        end_date,
      });

      dispatch({
        type: SUMMARIZE_MESSAGE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: SUMMARIZE_MESSAGE_FAILED,
      });
      return err.response?.data;
    }
  };

export const addPhoneNumberToRedux = (phoneNumber) => (dispatch) => {
  dispatch({
    type: ADD_PHONE_NUMBER,
    payload: phoneNumber,
  });
};
