import React, { Component } from "react";
import CESNewLogoSVG from "../../../Assets/svg/CESNewLogoSVG.svg";
import { Link } from "react-router-dom";

class NavBar extends Component {
  render() {
    return (
      <div className="flex justify-center items-center w-full h-[10rem] px-[2rem]">
        <div className="flex h-[6rem] justify-between items-center w-full max-w-[1300px] rounded-[5rem] border border-[#CDCDCD] shadow-custom-2 px-[3rem]">
          <div>
            <img className="size-[70px]" src={CESNewLogoSVG} alt="CES Logo" />
          </div>
          <div className="flex gap-[3.063rem] font-inter font-[600] text-[#504A4A] text-[1.5rem]">
            <h1 className=" text-[#8E3B8D]">Home</h1>
            <h1>About us</h1>
            <h1>Services</h1>
            <h1>Blog</h1>
          </div>
          <div className="flex gap-[3.063rem] font-inter font-[600] text-[#504A4A] text-[1.5rem]">
            <Link to="/signup">
              <button className="px-[2rem] py-[0.75rem] bg-[#A12F9F] text-[#F9F9F9] rounded-[2.5rem]">
                Signup
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
