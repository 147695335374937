import React, { Component } from "react";
import WhatappPNG from "../../../../Assets/png/whatsapp.png";

class WhatsAppNewChat extends Component {
  render() {
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <img src={WhatappPNG} alt="whatsapp" className="w-[10rem] h-auto" />
        <div className="text-[#000000] text-[2rem] font-[600] font-inter mt-[1rem]">
          Start a new chat
        </div>
        <div className="text-[#000000] text-[1.5rem] font-[500] font-inter mt-[0.5rem]">
          Select a contact to start a chat
        </div>
      </div>
    );
  }
}

export default WhatsAppNewChat;
