import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import rootReducer from "../Reducers";
import { createTransform } from "redux-persist";
import { initializeWebSockets } from "../Utils/websocket"; // Import WebSocket utility

// Transforms for specific reducers
const authenticationTransform = createTransform(
  (inboundState) => ({ isAuthenticated: inboundState.isAuthenticated }),
  (outboundState) => outboundState,
  { whitelist: ["authentication"] }
);

const analysisTransform = createTransform(
  (inboundState) => ({ AddedFileID: inboundState.AddedFileID }),
  (outboundState) => outboundState,
  { whitelist: ["analysis"] }
);

const isCollapsedTransform = createTransform(
  (inboundState) => ({ isCollapsed: inboundState.isCollapsed }),
  (outboundState) => outboundState,
  { whitelist: ["interactions"] }
);

const modelTransform = createTransform(
  (inboundState) => ({ model: inboundState.model }),
  (outboundState) => outboundState,
  { whitelist: ["AI"] }
);

const modelNameTransform = createTransform(
  (inboundState) => ({ modelName: inboundState.modelName }),
  (outboundState) => outboundState,
  { whitelist: ["AI"] }
);

const phoneNumberTransform = createTransform(
  (inboundState) => ({ phoneNumber: inboundState.phoneNumber }),
  (outboundState) => outboundState,
  { whitelist: ["messaging"] }
);

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authentication", "analysis", "ai", "messaging"], // Make sure this matches reducer names in rootReducer
  transforms: [
    authenticationTransform,
    analysisTransform,
    isCollapsedTransform,
    modelTransform,
    modelNameTransform,
    phoneNumberTransform,
  ],
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Initialize WebSocket connections after store creation
initializeWebSockets(store.dispatch);

export const persistor = persistStore(store);
export default store;
