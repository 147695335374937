import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./Component/Onboarding/SignUp";
import Login from "./Component/Onboarding/Login";
import ForgotPassword from "./Component/Onboarding/ForgotPassword";
import VerifyConfirmPassword from "./Component/Onboarding/VerifyConfirmPassword";
import VerifyEmail from "./Component/Onboarding/VerifyEmail";
import Confirmforgotpassword from "./Component/Onboarding/ConfirmPassword";
import DashboardRouter from "./Component/Dashboard/DashboardRouter";
import {
  PrivateRoute,
  HandleBack,
} from "./Component/Dashboard/AnalysisAndExtract/PrivateRoutes";
import AdminRoutes from "./Component/AdminDashboard/AdminRoutes";
import LandingPage from "./Component/LandingPage/LandingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<VerifyConfirmPassword />} />
        <Route
          path="/confirmforgotpassword"
          element={<Confirmforgotpassword />}
        />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <HandleBack />
              <DashboardRouter />
            </PrivateRoute>
          }
        />
        <Route path="/admin-login" element={<Login />} />
        <Route
          path="/admin-dashboard/*"
          element={
            <PrivateRoute>
              <AdminRoutes />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
