import React from "react";
import { useLocation } from "react-router-dom";

export function Void3() {
  const location = useLocation();

  const checkPath = () => {
    return location.pathname === "/dashboard/message-overview";
  };

  return (
    <div
      className={`shrink-0 ${
        checkPath() ? "w-[16.5rem]" : "w-[6.5rem]"
      } bg-[#F9F9F9]`}
    >
      <div className="w-full max-h-screen flex items-center justify-center text-white"></div>
    </div>
  );
}

export default Void3;
