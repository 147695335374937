import {
  FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  SUMMARIZE_MESSAGE_SUCCESS,
  SUMMARIZE_MESSAGE_FAILED,
  ADD_PHONE_NUMBER,
} from "../Actions/Types";

const initialState = {
  allChatSessions: null,
  allChatSessionsFetchSuccess: false,
  individualChatSession: null,
  individualChatSessionFetchSuccess: false,
  individualChatSessionFailMessage: null,
  messageSent: false,
  messageSentFailMessage: null,
  messageSummarySuccess: null,
  summarizedMessageAndTimeline: null,
  phoneNumber: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        allChatSessions: action.payload,
        allChatSessionsFetchSuccess: true,
      };
    case FETCH_ALL_WHATSAPP_MESSAGES_FAILED:
      return {
        ...state,
        allChatSessions: null,
        allChatSessionsFetchSuccess: false,
      };
    case FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        individualChatSession: action.payload,
        individualChatSessionFetchSuccess: true,
      };
    case FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED:
      return {
        ...state,
        individualChatSession: null,
        individualChatSessionFetchSuccess: false,
        individualChatSessionFailMessage: action.payload.detail,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSent: true,
      };
    case SEND_MESSAGE_FAILED:
      return {
        ...state,
        messageSent: false,
        messageSentFailMessage: action.payload.detail,
      };
    case RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE:
      return {
        ...state,
        messageSent: false,
        messageSentFailMessage: null,
      };
    case SUMMARIZE_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSummarySuccess: true,
        summarizedMessageAndTimeline: action.payload,
      };
    case SUMMARIZE_MESSAGE_FAILED:
      return {
        ...state,
        messageSummarySuccess: false,
        summarizedMessageAndTimeline: null,
      };
    case ADD_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    default:
      return state;
  }
}
