import React, { Component } from "react";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import XSVG from "../../../../Assets/svg/X.svg";
import IntentSVG from "../../../../Assets/svg/IntentSVG.svg";
import LanguageSVG from "../../../../Assets/svg/languageSVG.svg";
import SmileSVG from "../../../../Assets/svg/SmileSVG.svg";
import { Link } from "react-router-dom";

class ShowMessageIntent extends Component {
  render() {
    const { closeShowMessageIntent, showMessageHistory, showAIResponse } =
      this.props;
    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[1rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          <div className="flex justify-between px-[2rem] py-[1rem] bg-[#8E3B8D] rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <img src={PlaceholderImage1} alt="Placeholder Image 1" />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>Sarah Thomas</div>
                <div className=" font-inter font-[400] text-[1rem]">
                  Customer since Jan
                </div>
              </div>
            </div>
            <button onClick={closeShowMessageIntent}>
              <img src={XSVG} alt="Placeholder Image 1" />
            </button>
          </div>
          <div className="flex justify-between w-full">
            <div className="px-[2rem] w-[32%]">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                <img src={IntentSVG} alt="Intent" />
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Intent
                  </div>
                  <div className="font-inter font-[500] text-[1rem] bg-[#B61E21] w-fit px-[0.5rem] py-[0.25rem text-white rounded-[37px]">
                    Refund Request
                  </div>
                </div>
              </div>
            </div>
            <div className="px-[2rem] w-[32%]">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                <img src={LanguageSVG} alt="Intent" />
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Language
                  </div>
                  <div className="font-inter font-[400] text-[1rem] text-[#302D2D] w-fit">
                    English (U.S)
                  </div>
                </div>
              </div>
            </div>
            <div className="px-[2rem] w-[32%]">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                <img src={SmileSVG} alt="Intent" />
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Sentiment
                  </div>
                  <div className="font-inter font-[400] text-[1rem] text-[#302D2D] w-fit">
                    Neutral
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[2rem]">
            <button onClick={showMessageHistory}>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                Message
              </div>
            </button>
            <div className="flex w-full h-fit justify-center items-center py-[1rem]">
              <div className="w-[11px] py-[2.5rem] bg-[#8E3B8D] rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] h-[100%] bg-[#F8F9FA] border border-[#EBEAEA]">
                "Hello Sir, I hope this message finds you well. Everything is
                going fine on my end, and I truly appreciate you checking in.
                assist!"
              </div>
            </div>
          </div>
          <div className="px-[2rem]">
            <button>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                Message Summary
              </div>
            </button>
            <div className="flex w-full h-fit justify-center items-center py-[1rem]">
              <div className="w-[11px] py-[2.5rem] bg-[#8E3B8D] rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] h-[100%] bg-[#F8F9FA] border border-[#EBEAEA]">
                "Hello Sir, I hope this message finds you well. Everything is
                going fine on my end, and I truly appreciate you checking in.
                assist!"
              </div>
            </div>
          </div>
          <div className="px-[2rem]">
            <button onClick={showAIResponse}>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                AI Response
              </div>
            </button>
            <div className="flex w-full h-fit justify-center items-center py-[1rem]">
              <div className="w-[11px] py-[2.5rem] bg-[#8E3B8D] rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] h-[100%] bg-[#F8F9FA] border border-[#EBEAEA]">
                "Hello Sir, I hope this message finds you well. Everything is
                going fine on my end, and I truly appreciate you checking in.
                assist!"
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[1rem] px-[2rem] py-[1rem]">
            <Link to="/dashboard/message-overview/whatsapp/+2348067390962">
              <button className="bg-[#8E3B8D] text-white w-[100%] py-[1.5rem] font-[700] font-SFPro text-[1.5rem]">
                Reply Message
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowMessageIntent;
