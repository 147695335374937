import React, { Component } from "react";
import ReportSVG from "../../../Assets/svg/ReportSVG.svg";
import { Link } from "react-router-dom";

class HeroSection extends Component {
  render() {
    return (
      <div className="flex justify-center items-center w-full h-fit px-[2rem] pt-[6rem]">
        <div className="relative flex gap-[4.5rem] flex-col justify-between items-center w-full max-w-[1300px]">
          <div className="absolute right-[-14rem] bottom-[0rem]">
            <img src={ReportSVG} />
          </div>
          <div className="font-[700] text-[6rem] font-Alexandria text-center">
            <span className="text-[#A12F9F]">Supercharge</span> Your Data
            Analytics, Quick and Easy
          </div>
          <div className="font-[500] text-[2rem] font-Alexandria text-center">
            Analyze surveys and PDFs instantly with AI-driven insights. Get
            quick, clear results to make smarter decisions without the hassle
          </div>
          <div className="flex gap-[2rem] font-Alexandria font-[600] text-[1.5rem]">
            <button className="px-[2rem] py-[1rem] rounded-[2rem] border border-[#504A4A] text-[#303857]">
              Contact Us
            </button>
            <Link to="/login">
              <button className="px-[2rem] py-[1rem] bg-[#A12F9F] rounded-[2rem] text-white">
                Analyze Data
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroSection;
