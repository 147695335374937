import React, { Component } from "react";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import XSVG from "../../../../Assets/svg/X.svg";
import IntentSVG from "../../../../Assets/svg/IntentSVG.svg";
import LanguageSVG from "../../../../Assets/svg/languageSVG.svg";
import SmileSVG from "../../../../Assets/svg/SmileSVG.svg";
import ArrowleftPNG from "../../../../Assets/png/arrowleft.png";

class MessageHistory extends Component {
  render() {
    const { closeShowMessageIntent, backToMessageIntent,  } = this.props;
    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[4rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          <div className="flex justify-between px-[2rem] py-[1rem] bg-[#8E3B8D] rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <button onClick={backToMessageIntent}>
                <img src={ArrowleftPNG} alt="arrow left" />
              </button>
              <img src={PlaceholderImage1} alt="Placeholder Image 1" />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>Sarah Thomas</div>
                <div className=" font-inter font-[400] text-[1rem]">
                  Customer since Jan
                </div>
              </div>
            </div>
            <button onClick={closeShowMessageIntent}>
              <img src={XSVG} alt="Placeholder Image 1" />
            </button>
          </div>
          <div className="flex flex-col gap-[1rem]">
            <div className="flex gap-[1rem] px-[2rem]">
              <div className="flex items-center justify-center font-[400] font-inter text-[1.125rem]">
                Today 2:30pm
              </div>
              <div className="flex flex-col gap-[1rem]">
                <div className="px-[1rem] py-[0.75rem] bg-[#B61E21] w-fit rounded-[37px] text-white font-SFPro font-[700] text-[1rem]">
                  Refund Request
                </div>
                <div className="font-[500] font-inter text-[1.125rem] px-[1rem] py-[2rem] bg-[#F8F9FA] border border-[#EBEAEA] rounded-[7px]">
                  Hi there, I'd like to request a refund for order #4589. The
                  size wasn't right for me and I've already packaged it for
                  return.
                </div>
              </div>
            </div>
            <div className="flex gap-[1rem] px-[2rem]">
              <div className="flex items-center justify-center font-[400] font-inter text-[1.125rem]">
                Today 2:30pm
              </div>
              <div className="flex flex-col gap-[1rem]">
                <div className="px-[1rem] py-[0.75rem] bg-[#B61E21] w-fit rounded-[37px] text-white font-SFPro font-[700] text-[1rem]">
                  Refund Request
                </div>
                <div className="font-[500] font-inter text-[1.125rem] px-[1rem] py-[2rem] bg-[#F8F9FA] border border-[#EBEAEA] rounded-[7px]">
                  Hello! Unfortunately the items I received don't match what I
                  was expecting. I need to process a refund for my recent
                  purchase."
                </div>
              </div>
            </div>
            <div className="flex gap-[1rem] px-[2rem]">
              <div className="flex items-center justify-center font-[400] font-inter text-[1.125rem]">
                Today 2:30pm
              </div>
              <div className="flex flex-col gap-[1rem]">
                <div className="px-[1rem] py-[0.75rem] bg-[#55BC0A] w-fit rounded-[37px] text-white font-SFPro font-[700] text-[1rem]">
                  Product Inquiry
                </div>
                <div className="font-[500] font-inter text-[1.125rem] px-[1rem] py-[2rem] bg-[#F8F9FA] border border-[#EBEAEA] rounded-[7px]">
                  Hello! Unfortunately the items I received don't match what I
                  was expecting. I need to process a refund for my recent
                  purchase."
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageHistory;
