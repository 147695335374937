import React, { Component } from "react";
import ArrowleftPNG from "../../../../Assets/png/arrowleft.png";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import XSVG from "../../../../Assets/svg/X.svg";

class CustomizeAIResponse extends Component {
  state = {
    tone: "Neutral", // Default tone
    length: "Medium", // Default response length
    aiResponse:
      "This is the current AI response. Modify the tone or length and generate a new response.", // Dummy AI response
  };

  handleToneChange = (event) => {
    this.setState({ tone: event.target.value });
  };

  handleLengthChange = (event) => {
    this.setState({ length: event.target.value });
  };

  handleGenerateResponse = () => {
    const { tone, length } = this.state;

    // Simulate a new AI response
    const newResponse = `Generated a ${length.toLowerCase()} response with a ${tone.toLowerCase()} tone.`;
    this.setState({ aiResponse: newResponse });
  };

  render() {
    const { backToMessageIntent, closeShowAIResponse } = this.props;
    const { tone, length, aiResponse } = this.state;

    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[2rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          {/* Header */}
          <div className="flex justify-between px-[2rem] py-[1rem] bg-[#8E3B8D] rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <button onClick={backToMessageIntent}>
                <img src={ArrowleftPNG} alt="arrow left" />
              </button>
              <img src={PlaceholderImage1} alt="Placeholder" />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>Sarah Thomas</div>
                <div className="font-inter font-[400] text-[1rem]">
                  Customer since Jan
                </div>
              </div>
            </div>
            <button onClick={closeShowAIResponse}>
              <img src={XSVG} alt="close" />
            </button>
          </div>

          {/* Body */}
          <div className="flex flex-col px-[2rem] gap-[1.5rem]">
            {/* Current AI Response */}
            <div className="bg-gray-100 p-[1rem] rounded-[8px] border border-gray-300">
              <p className="text-[#333] font-[500] mb-[0.5rem]">
                Current AI Response:
              </p>
              <p className="text-[#555]">{aiResponse}</p>
            </div>

            {/* Tone Selector */}
            <label className="flex flex-col">
              <span className="text-[#333] font-[500]">Tone</span>
              <select
                value={tone}
                onChange={this.handleToneChange}
                className="p-[0.5rem] rounded-[8px] border border-gray-300"
              >
                <option value="Friendly">Friendly</option>
                <option value="Professional">Professional</option>
                <option value="Neutral">Neutral</option>
              </select>
            </label>

            {/* Response Length Selector */}
            <label className="flex flex-col">
              <span className="text-[#333] font-[500]">Response Length</span>
              <select
                value={length}
                onChange={this.handleLengthChange}
                className="p-[0.5rem] rounded-[8px] border border-gray-300"
              >
                <option value="Short">Short</option>
                <option value="Medium">Medium</option>
                <option value="Long">Long</option>
              </select>
            </label>

            {/* Generate Button */}
            <button
              onClick={this.handleGenerateResponse}
              className="bg-[#8E3B8D] text-white py-[0.75rem] rounded-[8px] font-[500] hover:bg-[#721c6f]"
            >
              Generate Response
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomizeAIResponse;
