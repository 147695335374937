// Whatsapp.jsx
import React, { Component } from "react";
import WhatsappChatList from "./WhatsappChatList";
import WhatsappChatStarted from "./WhatsappChatStarted";
import WhatsAppNewChat from "./WhatsAppNewChat";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import ChatAssistant from "../ChatAssistant";

class Whatsapp extends Component {
  state = {
    isChatAssistantVisible: false,
    // 1) We'll store the timestamps in parent state
    seekRange: null,
  };

  // Show chat assistant
  showChatAssistant = () => {
    this.setState({ isChatAssistantVisible: true });
  };

  // Close chat assistant
  closeChatAssistant = () => {
    this.setState({ isChatAssistantVisible: false });
  };

  // 2) This is the callback we pass to ChatAssistant
  // It receives the start/end timestamps and saves them in local state
  seekChatRange = (start, end) => {
    this.setState({ seekRange: { start, end } });
  };

  // A small helper to conditionally render the main chat area
  isChatStarted = () => {
    const { showChatAssistant, state } = this;

    // Checking if the URL ends with /whatsapp/<some-id> means a chat started
    if (
      this.props.location.pathname.includes(
        "/dashboard/message-overview/whatsapp/"
      )
    ) {
      return (
        <WhatsappChatStarted
          showChatAssistant={showChatAssistant}
          isChatAssistantVisible={state.isChatAssistantVisible}
          // 3) Pass the timestamps down to WhatsappChatStarted
          seekRange={this.state.seekRange}
        />
      );
    } else if (
      this.props.location.pathname.includes(
        "/dashboard/message-overview/whatsapp"
      )
    ) {
      // If no session, show new chat
      return <WhatsAppNewChat />;
    }
  };

  render() {
    const { isChatAssistantVisible } = this.state;

    return (
      <div className="w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <div className="flex bg-white w-full h-full gap-[1rem]">
          <WhatsappChatList />
          {this.isChatStarted()}

          {/* If the Chat Assistant is visible, render it */}
          {isChatAssistantVisible && (
            <ChatAssistant
              closeChatAssistant={this.closeChatAssistant}
              // Pass the parent's callback for seeking:
              seekChatRange={this.seekChatRange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouterHooks(Whatsapp);
