import React, { Component } from "react";
import placeholderImage from "../../../../Assets/svg/PlaceholderImage1.svg";
import Headset from "../../../../Assets/svg/Headset.svg";

class CallTranscript extends Component {
  render() {
    return (
      <div className="w-[70%] h-screen bg-[#F9F9F9] py-[3rem] px-[1rem] ">
        <div className="flex flex-col w-full h-full order bg-white border border-[#D9D9D9] rounded-[9px] gap-[1rem]">
          <div className="flex items-center px-[1rem] py-[1rem] gap-[1rept-[1rem]m] border-b bg-[#F9FAFB]">
            <img src={placeholderImage} alt="placeholder image" />
            <div className="font-[700] font-SFPro text-[1.5rem]">
              Jame Smith
            </div>
          </div>
          <div className="flex flex-col w-full py-[1rem] h- items-center gap-[1rem]">
            <div className="w-full h-fit flex items-center justify-center gap-[1rem]">
              <img src={Headset} alt="headset" />
              <div className="font-[500] font-inter text-[2rem]">
                Call summary: Timeline
              </div>
            </div>

            <div className="px-[2em] py-[0.5rem] bg-[#EBEAEA] font-[500] font-inter text-[1.25rem]">
              Today
            </div>
          </div>
          <div className="flex flex-col w-full px-[1rem] py-[1rem] gap-[2rem]">
            <div className="flex flex-col w-full py-[1rem] px-[1rem] border border-[#E2E0E0] bg-[#F9F9F9] rounded-[6px] gap-[1rem]">
              <div className="flex w-full justify-between font-inter text-[1.25rem]">
                <div className="font-[600]">Customer</div>
                <div className="font-[500]">9:30:15pm</div>
              </div>
              <div className="font-[500] text-[1.25rem] text-[#667085] text-justify">
                I am concerned about the timeline of Q1 deliverables . can we
                establish another time, it seems tights and may require
                adjustments. Given the current workload , this might not be
                realistic without additional resources.
              </div>
            </div>
            <div className="flex flex-col w-full py-[1rem] px-[1rem] border border-[#E2E0E0] bg-[#8E3B8D] rounded-[6px] gap-[1rem]">
              <div className="flex w-full justify-between font-inter text-[1.25rem]">
                <div className="font-[600] text-white">Agent (Bot)</div>
                <div className="font-[500] text-white">9:30:15pm</div>
              </div>
              <div className="font-[500] text-[1.25rem] text-white text-justify">
                I understand your concerns, we can revise the timeline going
                forward
              </div>
            </div>
            <div className="flex flex-col w-full py-[1rem] px-[1rem] border border-[#E2E0E0] bg-[#F9F9F9] rounded-[6px] gap-[1rem]">
              <div className="flex w-full justify-between font-inter text-[1.25rem]">
                <div className="font-[600]">Customer</div>
                <div className="font-[500]">9:30:15pm</div>
              </div>
              <div className="font-[500] text-[1.25rem] text-[#667085] text-justify">
                I am concerned about the timeline of Q1 deliverables . can we
                establish another time, it seems tights and may require
                adjustments. Given the current workload , this might not be
                realistic without additional resources.
              </div>
            </div>
            <div className="flex flex-col w-full py-[1rem] px-[1rem] border border-[#E2E0E0] bg-[#8E3B8D] rounded-[6px] gap-[1rem]">
              <div className="flex w-full justify-between font-inter text-[1.25rem]">
                <div className="font-[600] text-white">Agent</div>
                <div className="font-[500] text-white">9:30:15pm</div>
              </div>
              <div className="font-[500] text-[1.25rem] text-white text-justify">
                I understand your concerns, we can revise the timeline going
                forward
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallTranscript;
