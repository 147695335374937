import react, { Component } from "react";
import "./DualRing.css";

class DualRing extends Component {
  render() {
    return <div className="lds-dual-ring" bis_skin_checked="1"></div>;
  }
}

export default DualRing;
