import React, { Component } from "react";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import {
  ResetAnalysisReduxStateAction,
  ResetAnalysisMessageAction,
  FetchIndividualFileAction,
} from "../../../Actions/Analysis";
import BackbuttonSVG from "../../../Assets/svg/BackbuttonSVG.svg";

class DataAnalysisSummary extends Component {
  static propTypes = {
    csvAnalysisSuccessReport: PropTypes.string,
    csvAnalysisSuccess: PropTypes.bool,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    analysisFileName: PropTypes.string,
    ResetAnalysisMessageAction: PropTypes.func.isRequired,
    pdfAnalysisMessage: PropTypes.string,
    pdfAnalysisOutput: PropTypes.any,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    FetchIndividualFileAction: PropTypes.func.isRequired,
    isIndividualFileFetched: PropTypes.bool,
    AddedFileID: PropTypes.string,
  };

  componentDidMount() {
    const path = this.props.location.pathname;
    const segments = path.split("/");
    const id = segments[segments.length - 1];
    if (this.props.AddedFileID) {
      this.props.FetchIndividualFileAction(id);
    }
  }

  handlePath = () => {
    const { pathname } = this.props.location;
    return pathname.includes("/dashboard/pdf-analyzer/result-summary");
  };

  handleBackToHome = () => {
    if (
      this.props.location.pathname.includes(
        "/dashboard/pdf-analyzer/result-summary/"
      )
    ) {
      this.props.navigate("/dashboard/pdf-analyzer");
    } else if (
      this.props.location.pathname.includes("/dashboard/result-summary")
    ) {
      this.props.navigate("/dashboard");
    }

    // this.props.navigate("..");
    this.props.ResetAnalysisReduxStateAction();
    this.props.ResetAnalysisMessageAction();
  };

  handleCompare = () => {
    this.props.navigate("compare");
  };

  // Helper function to remove HTML tags
  stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  processDataToText = (data, indentLevel = 0) => {
    let output = "";
    const indent = "    ".repeat(indentLevel);

    if (typeof data === "string") {
      try {
        data = JSON.parse(data.replace(/'/g, '"'));
      } catch (e) {
        output += `${indent}${data}\n`;
        return output;
      }
    }

    if (Array.isArray(data)) {
      data.forEach((item) => {
        output += this.processDataToText(item, indentLevel);
      });
    } else if (typeof data === "object" && data !== null) {
      for (const [key, value] of Object.entries(data)) {
        output += `${indent}${key}:\n`;
        output += this.processDataToText(value, indentLevel + 1);
      }
    } else {
      output += `${indent}${data}\n`;
    }

    return output;
  };

  handlePathText = () => {
    const {
      location,
      csvAnalysisSuccessReport,
      pdfAnalysisMessage,
      pdfAnalysisOutput,
    } = this.props;

    if (location.pathname.includes("/dashboard/result-summary")) {
      return csvAnalysisSuccessReport || "No CSV analysis data available.";
    } else if (
      location.pathname.includes("/dashboard/pdf-analyzer/result-summary")
    ) {
      return pdfAnalysisOutput
        ? this.processDataToText(pdfAnalysisOutput)
        : pdfAnalysisMessage || "No PDF analysis data available.";
    }

    return "No data available.";
  };

  // Download report as TXT (with HTML tags removed)
  handleDownloadAsTXT = () => {
    const { analysisFileName, location } = this.props;
    let analysisContent = this.handlePathText();

    // Remove HTML tags
    analysisContent = this.stripHtmlTags(analysisContent);

    const element = document.createElement("a");
    const file = new Blob([analysisContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);

    const defaultFileName =
      location.pathname === "/dashboard/result-summary"
        ? "CSV_Analysis_Report"
        : "PDF_Analysis_Report";

    element.download = `${analysisFileName || defaultFileName}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // Download report as PDF (with HTML tags removed)
  handleDownloadAsPDF = () => {
    const { analysisFileName, location } = this.props;
    let analysisContent = this.handlePathText();

    // Remove HTML tags
    analysisContent = this.stripHtmlTags(analysisContent);

    const doc = new jsPDF();
    const margin = 10;
    const pageHeight = doc.internal.pageSize.getHeight();
    const lineHeight = 8;
    const maxLineWidth = doc.internal.pageSize.getWidth() - margin * 2;

    const lines = doc.splitTextToSize(analysisContent, maxLineWidth);
    let cursorY = margin;

    lines.forEach((line) => {
      if (cursorY + lineHeight > pageHeight - margin) {
        doc.addPage();
        cursorY = margin;
      }
      doc.text(line, margin, cursorY);
      cursorY += lineHeight;
    });

    const defaultFileName =
      location.pathname === "/dashboard/result-summary"
        ? "CSV_Analysis_Report"
        : "PDF_Analysis_Report";

    doc.save(`${analysisFileName || defaultFileName}.pdf`);
  };

  render() {
    const {
      csvAnalysisSuccess,
      pdfAnalysisMessage,
      pdfAnalysisOutput,
      analysisFileName,
      location,
      isIndividualFileFetched,
    } = this.props;
    const analysisContent = this.handlePathText();

    // Use includes to allow for dynamic IDs in the URLs
    const isCSVResult = location.pathname.includes("/dashboard/result-summary");
    const isPDFResult = location.pathname.includes(
      "/dashboard/pdf-analyzer/result-summary"
    );

    return (
      <div className="relative flex w-full justify-center items-center border-l">
        <div className="w-full flex flex-col px-[3rem] pt-[3rem] pb-[3rem] gap-[1rem]">
          <div className="flex flex-col gap-[1rem]">
            <button onClick={this.handleBackToHome} className="z-50">
              <img
                src={BackbuttonSVG}
                alt="back button"
                className="absolute top-[3.5rem] right-[5rem] w-[2rem] h-[2rem]"
              />
            </button>
            <div className="flex flex-col gap-[1rem] font-SFPro w-[30rem]">
              <div className="font-[700] text-[2rem] text-[#8E3B8D] leading-[2.449rem]">
                Data Analysis Summary
              </div>
              <div className="font-[500] text-[1.875rem] leading-[2.238rem] text-[#535252]">
                File name: {analysisFileName}
              </div>
            </div>
          </div>

          {this.handlePath() && (
            <div className="flex w-[100%] justify-between pr-[2rem]">
              <div className="font-[400] font-SFPro text-[1.75rem] text-[#302D2D]">
                Analysis Report
              </div>
              <button
                onClick={this.handleCompare}
                className="flex justify-center items-center text-white rounded-[0.313rem] w-[12.563rem] h-[3.125rem] bg-[#A12F9F] font-SFPro font-[700] text-[1rem]"
              >
                Show Comparison
              </button>
            </div>
          )}

          <div className="w-full px-[4rem] py-[4rem] h-fit bg-[#F8F8F8] report-container">
            {(csvAnalysisSuccess ||
              pdfAnalysisMessage ||
              pdfAnalysisOutput ||
              isIndividualFileFetched) &&
            analysisContent ? (
              isCSVResult ? (
                <div
                  className="font-[400] text-[1.75rem] leading-[2.088rem]"
                  dangerouslySetInnerHTML={{ __html: analysisContent }}
                />
              ) : (
                <pre className="font-[400] text-[1.75rem] leading-[2.088rem] whitespace-pre-wrap">
                  {analysisContent}
                </pre>
              )
            ) : (
              <div>No data available</div>
            )}
          </div>

          <div className="flex justify-end">
            <div className="flex gap-[0.625rem] relative right-[2rem]">
              <button
                onClick={this.handleDownloadAsTXT}
                className="w-[12.563rem] h-[3.125rem] bg-[#F4F3F3] text-[#302D2D] font-[700] text-[1rem] border-[#E2E0E0] border-solid border-[1px] font-SFPro rounded-[0.313rem]"
              >
                Download as TXT
              </button>
              <button
                onClick={this.handleDownloadAsPDF}
                className="w-[12.563rem] h-[3.125rem] bg-[#55BC0A] text-[#FFFFFF] font-[700] text-[1rem] font-SFPro rounded-[0.313rem]"
              >
                Download as PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvAnalysisSuccessReport: state.analysis.csvAnalysisSuccessReport,
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
  analysisFileName: state.analysis.analysisFileName,
  pdfAnalysisMessage: state.analysis.pdfAnalysisMessage,
  pdfAnalysisOutput: state.analysis.pdfAnalysisOutput,
  AddedFileID: state.analysis.AddedFileID,
  isIndividualFileFetched: state.analysis.isIndividualFileFetched,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    ResetAnalysisReduxStateAction,
    ResetAnalysisMessageAction,
    FetchIndividualFileAction,
  })(DataAnalysisSummary)
);
