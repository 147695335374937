import React, { Component } from "react";
import PlusSignSVG from "../../../Assets/svg/PlusSignSVG.svg";
import SendIconSVGDark from "../../../Assets/svg/SendIconSVGDark.svg";
import AIGenerateIconDarkSVG from "../../../Assets/svg/AIGenerateIconDarkSVG.svg";
import {
  SendMessageAction,
  resetHasRespondedToWhatsAppMessage,
  FetchIndividualWhatsAppMessagesAction,
} from "../../../Actions/Messaging";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";

class ChatInput extends Component {
  static propTypes = {
    SendMessageAction: PropTypes.func.isRequired,
    recipient: PropTypes.string.isRequired, // Phone number or ID of the recipient
    messageSent: PropTypes.bool.isRequired,
    FetchIndividualWhatsAppMessagesAction: PropTypes.func.isRequired,
    resetHasRespondedToWhatsAppMessage: PropTypes.func.isRequired,
    messageSent: PropTypes.bool.isRequired,
  };

  state = {
    message: "",
  };

  componentDidUpdate(prevProps) {
    const recipient = this.getPhoneNumber();

    // Check if the message was just sent
    if (!prevProps.messageSent && this.props.messageSent) {
      this.props.FetchIndividualWhatsAppMessagesAction(recipient);

      // Reset response state after 1 second
      setTimeout(() => {
        this.props.resetHasRespondedToWhatsAppMessage();
      }, 2000);
    }
  }

  getPhoneNumber = () => {
    const { pathname } = this.props.location;
    return pathname.split("/").pop();
  };

  handleInputChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleSendMessage = async () => {
    const { message } = this.state;
    const recipient = this.getPhoneNumber();

    if (!message.trim()) {
      alert("Please enter a message before sending.");
      return;
    }

    try {
      await this.props.SendMessageAction(recipient, message);
      this.setState({ message: "" }); // Clear the input field after sending
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  render() {
    return (
      <div className="absolute bottom-[0rem] w-full bg-[#EBEAEA] h-[6.625rem] flex items-center justify-center rounded-b-[9px]">
        <div className="flex w-full px-[1rem] gap-[0.5rem] justify-evenly">
          <button>
            <img src={PlusSignSVG} alt="Plus Sign" />
          </button>
          <button>
            <img src={AIGenerateIconDarkSVG} alt="AI Generate" />
          </button>
          <input
            className="w-[80%] bg-[#FFFFFF] rounded-[14px] focus:outline-none pl-[2rem]"
            type="text"
            value={this.state.message}
            onChange={this.handleInputChange}
            placeholder="Type your message here..."
          />
          <button onClick={this.handleSendMessage}>
            <img src={SendIconSVGDark} alt="Send Icon" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messageSent: state.messaging.messageSent,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    SendMessageAction,
    FetchIndividualWhatsAppMessagesAction,
    resetHasRespondedToWhatsAppMessage,
  })(ChatInput)
);
