// WhatsappChatStarted.jsx
import React, { Component } from "react";
import ChatInput from "../ChatInput";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import { FetchIndividualWhatsAppMessagesAction } from "../../../../Actions/Messaging";
import DualRing from "../../../Animations/DualRing/DualRing";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import DotsThreeVerticalSVG from "../../../../Assets/svg/DotsThreeVertical.svg";

class WhatsappChatStarted extends Component {
  static propTypes = {
    FetchIndividualWhatsAppMessagesAction: PropTypes.func.isRequired,
    individualChatSession: PropTypes.array,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object,

    // from parent:
    showChatAssistant: PropTypes.func,
    isChatAssistantVisible: PropTypes.bool,
    seekRange: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  };

  chatSocket = null;
  chatEndRef = React.createRef();

  // 1) Create an array to hold references to each message
  constructor(props) {
    super(props);
    this.messageRefs = [];
    this.state = {
      currentSessionId: null,
    };
  }

  componentDidMount() {
    const sessionId = this.getSessionIdFromPath();
    this.setState({ currentSessionId: sessionId }, () => {
      this.props.FetchIndividualWhatsAppMessagesAction(sessionId);
      this.openWebSocket(sessionId);
    });
  }

  componentDidUpdate(prevProps) {
    // If messages changed, scroll to bottom
    if (prevProps.individualChatSession !== this.props.individualChatSession) {
      this.scrollToBottom();
    }

    // If user navigated to a different chat
    const newSessionId = this.getSessionIdFromPath();
    if (newSessionId && newSessionId !== this.state.currentSessionId) {
      this.closeWebSocket();
      this.setState({ currentSessionId: newSessionId }, () => {
        this.props.FetchIndividualWhatsAppMessagesAction(newSessionId);
        this.openWebSocket(newSessionId);
      });
    }

    // 2) If the parent gave us a new seekRange, scroll to it
    if (prevProps.seekRange !== this.props.seekRange) {
      this.handleSeek(this.props.seekRange);
    }
  }

  componentWillUnmount() {
    this.closeWebSocket();
  }

  getSessionIdFromPath = () => {
    const { pathname } = this.props.location;
    return pathname.split("/").pop().replace(/\+/g, "");
  };

  openWebSocket = (sessionId) => {
    if (!sessionId) return;
    const wsUrl = `wss://${window.location.host}/ws/chat/${sessionId}/`;
    this.chatSocket = new WebSocket(wsUrl);

    this.chatSocket.onopen = () => {
      console.log("[WebSocket] Connected:", wsUrl);
    };

    this.chatSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("New message:", data);
      this.props.FetchIndividualWhatsAppMessagesAction(sessionId);
    };

    this.chatSocket.onerror = (error) => {
      console.error("[WebSocket] Error:", error);
    };

    this.chatSocket.onclose = (event) => {
      console.log("[WebSocket] Closed:", event.code, event.reason);
    };
  };

  closeWebSocket = () => {
    if (this.chatSocket) {
      this.chatSocket.close();
      this.chatSocket = null;
    }
  };

  scrollToBottom = () => {
    if (this.chatEndRef.current) {
      this.chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  };

  formatMessageDate = (date) => {
    const messageTime = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (messageTime >= today) {
      return "Today";
    } else if (messageTime >= yesterday) {
      return "Yesterday";
    } else {
      return messageTime.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  // 3) A utility to handle the "seekRange" scrolling
  handleSeek = (range) => {
    if (!range) return;
    const start = new Date(range.start);
    const end = new Date(range.end);

    const { individualChatSession } = this.props;
    if (!individualChatSession || individualChatSession.length === 0) return;

    // Find the first message whose timestamp >= start
    const indexToScroll = individualChatSession.findIndex(
      (msg) => new Date(msg.created_at) >= start
    );

    if (indexToScroll !== -1 && this.messageRefs[indexToScroll]) {
      // Smoothly scroll to that message
      this.messageRefs[indexToScroll].scrollIntoView({ behavior: "smooth" });
    } else {
      // If none found, optionally scroll to bottom or do something else
      console.warn("No messages found after that start timestamp");
    }
  };

  renderMessages = () => {
    const { individualChatSession } = this.props;

    if (!individualChatSession || individualChatSession.length === 0) {
      return (
        <div className="flex w-full h-screen justify-center items-center">
          <DualRing />
        </div>
      );
    }

    let lastMessageDate = null;

    return individualChatSession.map((message, index) => {
      const messageDate = new Date(message.created_at).toDateString();
      const showDateLabel = messageDate !== lastMessageDate;
      lastMessageDate = messageDate;

      return (
        <React.Fragment key={index}>
          {showDateLabel && (
            <div className="text-center font-inter font-[500] text-[0.875rem] text-gray-500 my-4">
              {this.formatMessageDate(message.created_at)}
            </div>
          )}
          {/* 4) Attach a ref to the outermost container of each message */}
          <div
            ref={(el) => (this.messageRefs[index] = el)}
            className={`flex ${
              message.message_type === "incoming" ? "" : "justify-end"
            }`}
          >
            <div
              className={`relative flex max-w-[70%] p-3 rounded-lg shadow ${
                message.message_type === "incoming"
                  ? "bg-white text-gray-900 border border-[#198754] border-opacity-[50%] w-[40%] py-[1.5rem]"
                  : "bg-[#8E3B8D] text-white w-[40%] py-[1.5rem]"
              }`}
            >
              <div>{message.body}</div>
              <div className="absolute right-[1rem] bottom-[0.5rem] text-[0.7rem]">
                {this.formatDate(message.created_at)}
              </div>
              <div
                className={`absolute top-2 ${
                  message.message_type === "incoming"
                    ? "left-[-8px]"
                    : "right-[-8px]"
                } h-4 w-4 rotate-45 ${
                  message.message_type === "incoming"
                    ? "bg-white border-[#198754] border-opacity-[50%] border-l border-b"
                    : "bg-[#8E3B8D]"
                }`}
              ></div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  render() {
    const { showChatAssistant, isChatAssistantVisible, individualChatSession } =
      this.props;

    return (
      <div className="relative w-full h-full border border-[#D9D9D9] rounded-[9px]">
        {/* Header */}
        <div className="w-full h-[6.875rem] bg-[#EBEAEA] px-[1rem] flex justify-between items-center gap-[1rem] rounded-t-[9px]">
          <div className="flex gap-[1rem]">
            <img src={PlaceholderImage1} alt="Chat Icon" />
            <div>
              <div className="font-[700] font-SFPro text-[1.5rem]">
                {this.state.currentSessionId
                  ? this.state.currentSessionId
                  : "Chat with User"}
              </div>
              <div className="font-inter font-[500] text-[0.875rem]">
                Tap profile for more info
              </div>
            </div>
          </div>
          <div className="flex gap-[1rem]">
            {!isChatAssistantVisible && (
              <button
                onClick={showChatAssistant}
                className="bg-[#302D2D] px-[1.5rem] py-[0.75rem] text-white font-[700] font-SFPro text-[1.25rem] rounded-[51px]"
              >
                Chat Assistant
              </button>
            )}
            <button>
              <img src={DotsThreeVerticalSVG} alt="Options" />
            </button>
          </div>
        </div>

        {/* Messages */}
        <div
          className="w-full flex flex-col gap-[1rem] px-4 overflow-y-auto"
          style={{ maxHeight: "calc(100% - 6.875rem - 6.625rem)" }}
        >
          {(!individualChatSession || individualChatSession.length === 0) && (
            <div className="text-center font-inter font-[400] text-[1.125rem] text-[#302D2D]">
              All messages in this system are{" "}
              <span className="text-[#8E3B8D]">Secured</span>
            </div>
          )}

          {/* Render the chat messages */}
          {this.renderMessages()}

          {/* The "bottom" ref for auto-scrolling */}
          <div ref={this.chatEndRef} />
        </div>

        {/* Message input */}
        <ChatInput />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  individualChatSession: state.messaging.individualChatSession,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchIndividualWhatsAppMessagesAction,
  })(WhatsappChatStarted)
);
