export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const ACCOUNT_VERIFICATION_SUCCESS = "ACCOUNT_VERIFICATION_SUCCESS";
export const ACCOUNT_VERIFICATION_FAIL = "ACCOUNT_VERIFICATION_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS =
  "FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS";
export const FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL =
  "FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL";

export const PASSWORD_CHANGED_SUCCESS = "PASSWORD_CHANGED_SUCCESS";
export const PASSWORD_CHANGED_FAIL = "PASSWORD_CHANGED_FAIL";

export const FLUSH_ALL_AUTHENTICATION_REDUX_STATE =
  "FLUSH_ALL_AUTHENTICATION_REDUX_STATE";

export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAILED = "TOKEN_REFRESH_FAILED";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const CSV_FILE_UPLOAD_SUCCESS = "CSV_FILE_UPLOAD_SUCCESS";
export const CSV_FILE_UPLOAD_FAIL = "CSV_FILE_UPLOAD_FAIL";

export const FILE_UPLOAD_TRIGGERED_SUCCESS = "FILE_UPLOAD_TRIGGERED_SUCCESS";
export const FILE_UPLOAD_TRIGGERED_FAILED = "FILE_UPLOAD_TRIGGERED_FAILED";

export const RESET_ANALYSIS_REDUX_STATE = "RESET_ANALYSIS_REDUX_STATE";

export const FETCH_CSV_SUCCESS = "FETCH_CSV_SUCCESS";
export const FETCH_CSV_FAIL = "FETCH_CSV_FAIL";

export const DELETE_CSV_SUCCESS = "DELETE_CSV_SUCCESS";
export const DELETE_CSV_FAIL = "DELETE_CSV_FAIL";

export const FETCH_CHAT_SESSIONS_SUCCESS = "FETCH_CHAT_SESSIONS_SUCCESS";
export const FETCH_CHAT_SESSIONS_FAIL = "FETCH_CHAT_SESSIONS_FAIL";

export const FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS =
  "FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS";
export const FETCH_INDIVIDUAL_CHAT_SESSION_FAIL =
  "FETCH_INDIVIDUAL_CHAT_SESSION_FAIL";

export const CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX =
  "CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX";

export const MESSAGE_SENT_TO_CHAT_BOT_SUCCESS =
  "MESSAGE_SENT_TO_CHAT_BOT_SUCCESS";
export const MESSAGE_SENT_TO_CHAT_BOT_FAIL = "MESSAGE_SENT_TO_CHAT_BOT_FAIL";

export const SET_HAS_RESPONDED = "SET_HAS_RESPONDED";

export const NEW_CHAT_SESSION_STARTED_SUCCESS =
  "NEW_CHAT_SESSION_STARTED_SUCCESS";
export const NEW_CHAT_SESSION_STARTED_FAIL = "NEW_CHAT_SESSION_STARTED_FAIL";

export const RESET_CONTINUE_CHAT_SESSION = "RESET_CONTINUE_CHAT_SESSION";

export const CHAT_SESSION_IS_NEW_RESET = "CHAT_SESSION_IS_NEW_RESET";

export const SESSION_DELETE_SUCCESS = "SESSION_DELETE_SUCCESS";
export const SESSION_DELETE_FAIL = "SESSION_DELETE_FAIL";

export const CSV_ANALYSIS_SUCCESS = "CSV_ANALYSIS_SUCCESS";
export const CSV_ANALYSIS_FAIL = "CSV_ANALYSIS_FAIL";

export const SENT_TO_MAIL = "RECIEVE_IN_MAIL";
export const RENDER_ON_SITE = "RENDER_ON_SITE";

export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const ADD_ANALYSIS_FILE_NAMES_TO_REDUX =
  "ADD_ANALYSIS_FILE_NAMES_TO_REDUX";

export const RESET_ANALYSIS_MESSAGE_REDUX_STATE =
  "RESET_ANALYSIS_MESSAGE_REDUX_STATE";

export const FETCH_INDIVIDUAL_FILE_SUCCESS = "FETCH_INDIVIDUAL_FILE_SUCCESS";
export const FETCH_INDIVIDUAL_FILE_FAIL = "FETCH_INDIVIDUAL_FILE_FAIL";

export const RENDER_PDF_FILE_SUCCESS = "RENDER_PDF_FILE_SUCCESS";
export const RENDER_PDF_FILE_FAIL = "RENDER_PDF_FILE_FAIL";

export const ADD_ANALYSIS_FILE_ID_TO_REDUX = "ADD_ANALYSIS_FILE_ID_TO_REDUX";

export const FAKE_CSV_ANALYSIS_SUCCESS = "FAKE_CSV_ANALYSIS_SUCCESS";

export const COLLAPSE_CX2_SIDEBAR_ACTIVE = "COLLAPSE_CX2_SIDEBAR_ACTIVE";
export const COLLAPSE_CX2_SIDEBAR_INACTIVE = "COLLAPSE_CX2_SIDEBAR_INACTIVE";

export const PDF_ANALYSIS_COMPLETE_POP_UP = "PDF_ANALYSIS_COMPLETE_POP_UP";
export const PDF_ANALYSIS_COMPLETE_POP_UP_RESET =
  "PDF_ANALYSIS_COMPLETE_POP_UP_RESET";

export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAIL = "FETCH_ALL_USERS_FAIL";

export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAIL = "BAN_USER_FAIL";

// export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
// export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const SERVICE_ACCESS_GRANTED_SUCCESS = "SERVICE_ACCESS_GRANTED_SUCCESS";
export const SERVICE_ACCESS_GRANTED_FAIL = "SERVICE_ACCESS_GRANTED_FAIL";

export const CLEAR_INDIVIDUAL_CHAT_SESSION = "CLEAR_INDIVIDUAL_CHAT_SESSION";

export const HAS_CLICKED_ON_SEND_ACTIVE = "HAS_CLICKED_ON_SEND_ACTIVE";
export const HAS_CLICKED_ON_SEND_INACTIVE = "HAS_CLICKED_ON_SEND_INACTIVE";

export const FETCH_FORM_TYPES_SUCCESS = "FETCH_FORM_TYPES_SUCCESS";
export const FETCH_FORM_TYPES_FAIL = "FETCH_FORM_TYPES_FAIL";

export const CREATE_FORM_INSTANCE_SUCCESS = "CREATE_FORM_INSTANCE_SUCCESS";
export const CREATE_FORM_INSTANCE_FAIL = "CREATE_FORM_INSTANCE_FAIL";

export const FETCH_FORM_STRUCTURE_SUCCESS = "FETCH_FORM_STRUCTURE_SUCCESS";
export const FETCH_FORM_STRUCTURE_FAIL = "FETCH_FORM_STRUCTURE_FAIL";

export const ADD_FORM_INSTANCE_ID_TO_REDUX = "ADD_FORM_INSTANCE_ID_TO_REDUX";

export const FORM_ENTRY_ADDED_SUCCESS = "FORM_ENTRY_ADDED_SUCCESS";
export const FORM_ENTRY_ADDED_FAIL = "FORM_ENTRY_ADDED_FAIL";

export const RESET_FORM_REDUX_STATE = "RESET_FORM_REDUX_STATE";

export const FORM_ENTRY_RENDERED_SUCCESS = "FORM_ENTRY_RENDERED_SUCCESS";
export const FORM_ENTRY_RENDERED_FAIL = "FORM_ENTRY_RENDERED_FAIL";

export const ADDED_AI_MODEL = "ADDED_AI_MODEL";

export const FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS =
  "FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS";
export const FETCH_ALL_WHATSAPP_MESSAGES_FAILED =
  "FETCH_ALL_WHATSAPP_MESSAGES_FAILED";

export const FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS =
  "FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS";
export const FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED =
  "FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED";

export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE =
  "RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE";

export const SUMMARIZE_MESSAGE_SUCCESS = "SUMMARIZE_MESSAGE_SUCCESS";
export const SUMMARIZE_MESSAGE_FAILED = "SUMMARIZE_MESSAGE_FAILED";

export const ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER";