import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";

import MagnifyingGlassSVG from "../../../../Assets/svg/MagnifyingGlassSVG.svg";
import DotsThreeSVG from "../../../../Assets/svg/DotsThreeSVG.svg";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import DualRing from "../../../Animations/DualRing/DualRing";

import {
  FetchAllWhatAppMessagesAction,
  FetchIndividualWhatsAppMessagesAction,
  addPhoneNumberToRedux,
} from "../../../../Actions/Messaging";

class WhatsappChatList extends Component {
  static propTypes = {
    FetchAllWhatAppMessagesAction: PropTypes.func.isRequired,
    allChatSessions: PropTypes.array,
    allChatSessionsFetchSuccess: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    FetchIndividualWhatsAppMessagesAction: PropTypes.func.isRequired,
    individualChatSession: PropTypes.object,
    dispatch: PropTypes.func,
    location: PropTypes.object.isRequired,
    addPhoneNumberToRedux: PropTypes.func.isRequired,
  };

  state = {
    isInChatList: null,
  };

  componentDidMount() {
    this.props.FetchAllWhatAppMessagesAction();
    const isInCurrentPath = this.getPath();
    if (isInCurrentPath) {
      this.OpenWebSocket("all");
    }
    this.setState({ isInChatList: isInCurrentPath });
  }

  componentDidUpdate(prevProps) {
    const isInCurrentPath = this.getPath();
    const wasInPreviousPath = this.getPath(prevProps.location.pathname);

    if (isInCurrentPath && !wasInPreviousPath) {
      this.OpenWebSocket("all");
    } else if (!isInCurrentPath && wasInPreviousPath) {
      this.closeWebSocket();
    }
  }

  componentWillUnmount() {
    this.closeWebSocket();
  }

  OpenWebSocket = (path) => {
    if (!path) {
      console.error("WebSocket did not open due to missing path");
      return;
    }

    const wsUrl = `wss://${window.location.host}/ws/chat/${path}/`;
    console.log("Opening WebSocket connection to:", wsUrl);

    this.chatSocket = new WebSocket(wsUrl);

    this.chatSocket.onopen = () => {
      console.log("WebSocket connection opened");
    };

    this.chatSocket.onmessage = (e) => {
      console.log("Received WebSocket message:", e.data);
      this.props.FetchAllWhatAppMessagesAction();
    };

    this.chatSocket.onerror = (e) => {
      console.error("WebSocket error occurred:", e);
    };

    this.chatSocket.onclose = (e) => {
      console.log("WebSocket connection closed:", e.reason);
      // Optional: Reconnect logic
      setTimeout(() => {
        console.log("Reconnecting WebSocket...");
        this.OpenWebSocket(path);
      }, 5000);
    };
  };

  closeWebSocket = () => {
    if (this.chatSocket) {
      this.chatSocket.close();
      console.log("WebSocket connection closed");
    }
  };

  getPath = (pathname = this.props.location.pathname) => {
    // Check if the pathname starts with the desired base path
    return pathname.startsWith("/dashboard/message-overview/whatsapp/");
  };

  continueChat = (number) => {
    console.log("Continue chat with:", number);
    this.props.FetchIndividualWhatsAppMessagesAction(number);
    this.props.navigate(`/dashboard/message-overview/whatsapp/${number}`);
    this.props.FetchIndividualWhatsAppMessagesAction(number);
    this.props.addPhoneNumberToRedux(number);
  };

  renderChatSessions = () => {
    const { allChatSessions, allChatSessionsFetchSuccess } = this.props;

    if (!allChatSessionsFetchSuccess) {
      return (
        <div className="flex justify-center items-center w-full h-full">
          <DualRing />
        </div>
      );
    }

    if (!allChatSessions || allChatSessions.length === 0) {
      return <div>No chat sessions available</div>;
    }

    return allChatSessions.map((session, index) => {
      const lastMessage =
        session.messages.length > 0
          ? session.messages[session.messages.length - 1]
          : null;

      const truncateMessage = (message) =>
        message && message.length > 15
          ? `${message.substring(0, 15)}...`
          : message;

      return (
        <button
          onClick={() => this.continueChat(session.user_phone_number)}
          key={index}
          className="flex overflow-hidden gap-[1rem] border-b-[1px] border-[#D9D9D9] pb-[1rem]"
        >
          <img
            src={session.profileImage || PlaceholderImage1}
            alt={session.name || "Chat Placeholder"}
          />
          <div className="w-full flex justify-between items-center">
            <div>
              <div className="w-full font-[700] font-SFPro text-fit text-left ">
                {session.user_phone_number || "Unknown"}
              </div>
              <div className="flex font-[500] font-inter text-fit text-[#5D5D5D] text-left">
                {lastMessage
                  ? truncateMessage(lastMessage.body)
                  : "No message available"}
              </div>
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="font-[400] font-SFPro text-[0.875rem]">
                {/* Optionally format the date */}
              </div>
              {session.unreadCount > 0 && (
                <div className="size-[1.25rem] rounded-[50%] bg-[#8E3B8D] text-center text-white">
                  {session.unreadCount}
                </div>
              )}
            </div>
          </div>
        </button>
      );
    });
  };

  render() {
    const { phoneNumber } = this.state;
    return (
      <div className="flex flex-col w-[30%] h-full gap-[1rem] px-[1rem] pt-[2rem] border-[1px] border-[#D9D9D9] rounded-[9px]">
        <div className="flex justify-between">
          <div className="font-[700] text-[1.5rem] font-SFPro">
            Whatsapp Messages
          </div>
          <img src={DotsThreeSVG} alt="Dots Three" />
        </div>
        <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
        <div className="flex w-full h-[3.625rem] justify-between gap-[1rem] relative">
          <img
            className="absolute top-[25%] left-[1rem] z-20 cursor-pointer size-[1.688rem]"
            src={MagnifyingGlassSVG}
            alt="Search Icon"
          />
          <input
            type="text"
            placeholder="Search"
            className="w-full py-4 pl-[4rem] font-SFPro text-[1.5rem] bg-[#F0F2F5] font-[400] border border-[#DDD9D9] focus:border focus:border-[#A12F9F] outline-none rounded-[7px] shadow-sm"
          />
        </div>
        <div className="p-[1rem] flex flex-col gap-[1rem] h-full">
          {this.renderChatSessions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allChatSessions: state.messaging.allChatSessions,
  allChatSessionsFetchSuccess: state.messaging.allChatSessionsFetchSuccess,
  individualChatSession: state.messaging.individualChatSession,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchAllWhatAppMessagesAction,
    FetchIndividualWhatsAppMessagesAction,
    addPhoneNumberToRedux,
  })(WhatsappChatList)
);
