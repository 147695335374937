import React, { Component } from "react";
import IncomingCallSVG from "../../../../Assets/svg/IncomingCall.svg";
import OutgoingCallsSVG from "../../../../Assets/svg/OutgoingCall.svg";
import placeholderImage from "../../../../Assets/svg/PlaceholderImage1.svg";

class CallList extends Component {
  render() {
    return (
      <div className="w-[30%] h-full py-[3rem] px-[1rem]">
        <div className="w-full h-full  border border-[#D9D9D9] rounded-[9px]">
          <div className="flex w-full px-[2rem] py-[0.5rem] border-b">
            <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
              <img src={IncomingCallSVG} alt="Incoming call" />
              <div className="font-[700] font-SFPro text-[1.5rem]">
                Incoming Calls
              </div>
            </div>
          </div>
          <div>
            <div className="flex w-full px-[2rem] bg-[#F7F8FA] border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    James Smith
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full px-[2rem] bg-white border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    Mandy Luiz
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full px-[2rem] bg-white border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    Alex Sandro
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full px-[2rem] py-[0.5rem] border-b">
            <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
              <img src={OutgoingCallsSVG} alt="Incoming call" />
              <div className="font-[700] font-SFPro text-[1.5rem]">
                Outgoing Calls
              </div>
            </div>
          </div>
          <div>
            <div className="flex w-full px-[2rem] bg-white border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    Samuel Karl
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full px-[2rem] bg-white border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    Alex Sandro
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full px-[2rem] bg-white border-b">
              <div className="flex w-full items-center py-[1rem] pl-[1rem] gap-[1rem]">
                <img src={placeholderImage} />
                <div>
                  <div className="font-[700] font-inter text-[1.25rem]">
                    Mandy Luiz
                  </div>
                  <div className="font-[400] font-inter text-[1.25rem] text-[#667085]">
                    9:30PM
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallList;
