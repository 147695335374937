import React, { Component } from "react";
import CallList from "./CallList";
import CallTranscript from "./CallTranscript";

class Calls extends Component {
  render() {
    return (
      <div className="flex w-full h-screen bg-[#F9F9F9]">
        <CallList />
        <CallTranscript />
      </div>
    );
  }
}

export default Calls;
