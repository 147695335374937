// websocket.js
import {
  FetchAllWhatAppMessagesAction,
  FetchIndividualWhatsAppMessagesAction,
} from "../Actions/Messaging";

let chatSocket = null;
let allChatsSocket = null;

export const initializeWebSockets = (dispatch, sessionId = null) => {
  // Close existing WebSocket connections if they exist
  if (chatSocket) chatSocket.close();
  if (allChatsSocket) allChatsSocket.close();

  // Initialize WebSocket for a specific chat session
  if (sessionId) {
    chatSocket = new WebSocket(
      `${window.location.protocol === "https:" ? "wss://" : "ws://"}${
        window.location.host
      }/ws/chat/${sessionId}/`
    );

    chatSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("New message in session:", data);

      // Dispatch action to refresh the specific chat session
      dispatch(FetchIndividualWhatsAppMessagesAction(sessionId));
    };

    chatSocket.onerror = (error) =>
      console.error("Chat WebSocket error:", error);
  }

  // Initialize WebSocket for all chat sessions
  allChatsSocket = new WebSocket(
    `${window.location.protocol === "https:" ? "wss://" : "ws://"}${
      window.location.host
    }/ws/chat/all/`
  );

  allChatsSocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("Updated chat sessions:", data);

    // Dispatch action to refresh all chat sessions
    dispatch(FetchAllWhatAppMessagesAction());
  };

  allChatsSocket.onerror = (error) =>
    console.error("All chats WebSocket error:", error);
};
