// ChatAssistant.jsx
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { SummarizeMessage } from "../../../Actions/Messaging";
import Spinner from "../../Animations/Spinner/Spinner";
import XSVG from "../../../Assets/svg/X.svg";

class ChatAssistant extends Component {
  state = {
    start_date: null,
    end_date: null,
    isSummarizing: false,
  };

  static propTypes = {
    SummarizeMessage: PropTypes.func.isRequired,
    messageSummarySuccess: PropTypes.bool,
    summarizedMessageAndTimeline: PropTypes.object,
    phoneNumber: PropTypes.string.isRequired,
    closeChatAssistant: PropTypes.func.isRequired,
    // 1) We add a new prop seekChatRange, which we'll get from the parent
    seekChatRange: PropTypes.func,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();
    const { phoneNumber, SummarizeMessage } = this.props;
    const { start_date, end_date } = this.state;

    if (!start_date || !end_date) {
      alert("Please select a valid date range.");
      return;
    }

    // For demo, we’re using a hard-coded phone number; you may change accordingly
    SummarizeMessage("+2348067390962", start_date, end_date);

    this.setState({ isSummarizing: true });
  };

  // 2) handleSeek method: dispatch or pass the timestamps to the parent
  handleSeek = () => {
    const { summarizedMessageAndTimeline, seekChatRange } = this.props;
    if (!summarizedMessageAndTimeline) {
      alert("No summary has been generated yet.");
      return;
    }

    const { start_timestamp, end_timestamp } = summarizedMessageAndTimeline;

    // If you want the parent to handle the logic, call the callback:
    if (seekChatRange) {
      seekChatRange(
        this.props.summarizedMessageAndTimeline?.start_timestamp,
        this.props.summarizedMessageAndTimeline?.end_timestamp
      );
    }
  };

  componentDidUpdate(prevProps) {
    const { summarizedMessageAndTimeline, messageSummaryError } = this.props;

    // Stop spinner if request is successful
    if (
      prevProps.summarizedMessageAndTimeline !== summarizedMessageAndTimeline &&
      summarizedMessageAndTimeline
    ) {
      this.setState({ isSummarizing: false });
    }

    // Stop spinner if an error occurs
    if (
      prevProps.messageSummaryError !== messageSummaryError &&
      messageSummaryError
    ) {
      this.setState({ isSummarizing: false });
    }
  }

  render() {
    const { closeChatAssistant } = this.props;
    const { start_date, end_date, isSummarizing } = this.state;

    return (
      <div className="w-[40%] flex flex-col gap-[2rem] border border-[#D9D9D9] rounded-[9px]">
        <div className="flex items-center justify-between py-[2rem] px-[2rem] bg-[#8E3B8D] text-white rounded-t-[9px]">
          <div>
            <div className="font-[700] font-SFPro text-[1.5rem]">
              CES Chat Assistant
            </div>
          </div>
          <button onClick={closeChatAssistant}>
            <img src={XSVG} alt="Chat Assistant" />
          </button>
        </div>

        <div className="px-[2rem] font-SFPro font-[700] text-[1.25rem]">
          Select Date Range
        </div>
        <div className="px-[2rem] flex gap-[1rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="font-[500] font-inter text-[1rem]">Start Date:</div>
            <input
              type="date"
              name="start_date"
              value={start_date || ""}
              onChange={this.onChange}
              className="w-full h-[2.5rem] bg-[#F7F8FA] border border-[#D1D4DA] rounded-[9px] px-[1rem]"
            />
          </div>
          <div className="flex flex-col gap-[1rem]">
            <div className="font-[500] font-inter text-[1rem]">End Date:</div>
            <input
              type="date"
              name="end_date"
              value={end_date || ""}
              onChange={this.onChange}
              className="w-full h-[2.5rem] bg-[#F7F8FA] border border-[#D1D4DA] rounded-[9px] px-[1rem]"
            />
          </div>
        </div>
        <div className="px-[2rem] flex justify-center">
          <button
            onClick={this.handleSubmit}
            className="bg-[#8E3B8D] w-full py-[1rem] text-white font-inter font-[700] text-[1.25rem] rounded-[9px] flex justify-center items-center gap-[0.5rem]"
          >
            <div>Summarize</div> {isSummarizing && <Spinner />}
          </button>
        </div>

        {/* SEEK Button */}
        <div className="px-[2rem] flex justify-center">
          <button
            onClick={this.handleSeek}
            className="bg-[#8E3B8D] w-full py-[1rem] text-white font-inter font-[700] text-[1.25rem] rounded-[9px] flex justify-center items-center gap-[0.5rem]"
          >
            <div>Seek</div>
          </button>
        </div>

        <div className="px-[2rem] pb-[2rem] font-SFPro text-[1rem] overflow-y-auto">
          <div className="bg-[#F8F9FA] p-[1rem] rounded-[9px]">
            <div
              className="bg-[#F8F9FA]"
              dangerouslySetInnerHTML={{
                __html: this.props.summarizedMessageAndTimeline?.summary || "",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messageSummarySuccess: state.messaging.messageSummarySuccess,
  summarizedMessageAndTimeline: state.messaging.summarizedMessageAndTimeline,
  phoneNumber: state.messaging.phoneNumber,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    SummarizeMessage,
  })(ChatAssistant)
);
