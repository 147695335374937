import React, { Component } from "react";
import Customize from "./Customize";

class CustomizeAndMoreInformation extends Component {
  state = {
    isCustomizeActive: false, // Tracks if the dropdown is active
  };

  handleCustomizeResponse = () => {
    // Toggle dropdown visibility
    this.setState((prevState) => ({
      isCustomizeActive: !prevState.isCustomizeActive,
    }));
  };

  closeCustomize = () => {
    this.setState({ isCustomizeActive: false });
  };

  render() {
    const { ShowMessageIntent } = this.props;

    return (
      <div className="absolute flex flex-col w-[17rem] z-50 right-0 shadow-custom-2">
        {/* Show the Customize component only when isCustomizeActive is true */}
        {this.state.isCustomizeActive && (
          <Customize closeCustomize={this.closeCustomize} />
        )}
        <div
          className="px-[1rem] py-[1rem] text-center bg-white hover:bg-[#EEEDED] font-inter font-[400] text-[1.5rem] cursor-pointer"
          onClick={this.handleCustomizeResponse} // Toggle the dropdown
        >
          Customize response
        </div>
        <div
          onClick={ShowMessageIntent}
          className="px-[1rem] py-[1rem] text-center bg-white hover:bg-[#EEEDED] font-inter font-[400] text-[1.5rem] cursor-pointer"
        >
          More information
        </div>
      </div>
    );
  }
}

export default CustomizeAndMoreInformation;
